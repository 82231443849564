import { api } from './configs/axiosConfig';

const endPoint = "/sections";

export const sectionAPI = {
    getSections: async function () {
        const reponse = await api.request({
            url: endPoint,
            method: 'GET'
        });

        return reponse.data;
    }
}