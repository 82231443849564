import { api } from './configs/axiosConfig';

const endPoint = "/correction-rejection";

export const correctionRejectionAPI = {
    getCorrectionRejection: async function (type) {
        const reponse = await api.request({
            url: endPoint,
            method: 'GET',
            params: { type }
        });

        return reponse.data;
    }
}