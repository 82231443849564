import { useEffect, useState } from "react";
import QRCodeScanner from '../qrCodeScanner';

import { toast } from 'react-toastify';

import Spinner from "../../components/spinner/spinner";
import Sections from "../../components/sections";

import { jobAPI } from "../../api/jobAPI";

import CorrectionRejectionReason from '../../components/correctionRejectionReason'

export default function JobInOutReturn({ type }) {
    const [isProcessing, setIsProcessing] = useState(false);

    const [isShowQrReader, setIsShowQrReader] = useState(false);
    const [jobNo, setJobNo] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [doctor, setDoctor] = useState('');
    const [sectionCode, setSectionCode] = useState('');
    const [reason, setReason] = useState('');

    const getJobDetails = (jobNo) => {
        setDeliveryDate('');
        setDoctor('');

        if (jobNo.trim().length === 0) return;

        setIsProcessing(true);

        jobAPI.getJobDetails(jobNo).then((data) => {
            setDeliveryDate(data.expectDate);
            setDoctor(data.doctorName);
            setSectionCode(data.currentNextSection);
        }).finally(() => setIsProcessing(false));
    }

    const clearForm = () => {

        setJobNo('');
        setDeliveryDate('');
        setDoctor('');
        setReason('');
    }

    const save = () => {
        if (sectionCode.trim().length === 0) { toast.warn("Please select a section"); return; }

        if (jobNo.trim().length === 0)
        { toast.warn("Please scan / enter job #"); return; }

        if (deliveryDate.trim().length === 0 || doctor.trim().length === 0) { toast.warn("Please scan / enter valid job #"); return; }

        if ((type === "Reject" || type === "Correction") && reason.trim().length === 0)
        { toast.warn("Please select a reason"); return; }

        if (window.confirm("Do you want to save ?") === false) return;

        setIsProcessing(true);

        jobAPI.saveJobInOutReturn(type, jobNo, sectionCode, reason).then((data) => {
            clearForm();
            document.getElementById(`modalClose${type}`).click();
            toast.success("Successfully saved.");
            
        }).finally(() => { setIsProcessing(false);  });
    }

    const onChangeJobNo = (jobNo) => {
        setDeliveryDate('');
        setDoctor('');
        setReason('');

        setJobNo(jobNo);
    }

    return (
        <>
            <button type="button" className="btn btn-primary form-control" data-bs-toggle="modal" data-bs-target={`#modalJob${type}`}>
                {type}
            </button>

            <div class="modal fade" id={`modalJob${type}`} tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Job {type}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                id={`modalClose${type}`} ></button>
                        </div>
                        <div class="modal-body">
                            {isProcessing && <div className="text-center"><Spinner /></div>}
                            {isProcessing === false &&
                                <div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            Section
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <Sections setSectionCode={setSectionCode} sectionCode={sectionCode} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            Job #
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 pe-0">
                                            <input type="text" className="form-control" value={jobNo}
                                                onChange={(e) => onChangeJobNo(e.target.value)}
                                                onBlur={(e) => getJobDetails(e.target.value)} />
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                            <button type="button" className="btn btn-success form-control"
                                                onClick={() => setIsShowQrReader(true)}>
                                                Scan
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            Expect.Date
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <input type="text" className="form-control" readOnly="true" value={deliveryDate} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            Doctor
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <input type="text" className="form-control" readOnly="true" value={doctor} />
                                        </div>
                                    </div>
                                    {
                                        type === "Reject" || type === "Correction" ?
                                        <div className="row mt-1">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                                    <CorrectionRejectionReason type={type} setSelectedReason={setReason} selectedReason={reason} />
                                            </div>
                                        </div>
                                        : ""
                                    }
                                    <div className="row mt-2">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                            <button type="button" className="btn btn-primary form-control"
                                                onClick={() => save()}>Save</button>
                                            <button type="button" className="btn btn-secondary form-control mt-1"
                                                onClick={() => clearForm()}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {isShowQrReader && <QRCodeScanner setIsShowQrReader={setIsShowQrReader} setQrCode={setJobNo} triggerAfterScan={getJobDetails} />}
        </>
    );
}