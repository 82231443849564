import { useEffect, useState } from "react";
import { correctionRejectionAPI } from '../../api/correctionRejectionAPI';

export default function CorrectionRejectionReason({ setSelectedReason, selectedReason, type }) {
    const [reasons, setReasons] = useState([]);

    useEffect(() => {
        correctionRejectionAPI.getCorrectionRejection(type).then((data) => setReasons(data));
    }, [])

    const onChangeReason = (e) => {
        setSelectedReason(e.target.value);
    }

    return (
        <select className="form-select" onChange={onChangeReason} value={selectedReason} >
            <option value="">Select a reason</option>
            {
                reasons.map((data) => (
                    <option key={data.code} value={data.description}>{data.description}</option>
                ))
            }
        </select>
    );
}