import { useEffect, useState, useRef } from "react";
import { jobAPI } from '../../api/jobAPI';

import Spinner from "../../components/spinner/spinner";

export default function MyJobs() {
    const didMount = useRef(false);

    const [isProcessing, setIsProcessing] = useState(true);
    const [target, setTarget] = useState("");
    const [achieved, setAchieved] = useState([]);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            jobAPI.getTargets().then((data) => {
                setAchieved(data.achievedDetails);
                setTarget(data.target);
            }).finally(() => setIsProcessing(false));
        }
    }, []);

    return (
        <div className="row">
            <div className="col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                <h4 className="text-center">My Job (Last three months)</h4>
                {isProcessing && <div className="text-center"><Spinner /></div>}
                {isProcessing === false &&
                    <>
                        <h5>Current Target : {target}</h5>
                        <table className="table table-hover">
                            <thead className="thead--color">
                                <tr>
                                    <td className="text-start">Month</td>
                                    <td className="text-end">Completed</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    achieved.map((data, index) => (
                                        <tr key={index}>
                                            <td className="text-start">{`${data.year}/${data.month}`}</td>
                                            <td className="text-end">{`${data.total}`}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </>
                }
            </div>
            <div className="col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
        </div>
    );
}