import { Routes,Route, Navigate } from 'react-router-dom';

import { mainMenuParentRoutePath } from './routePath';

import useToken from '../common/hooks/useToken';
import useUserName from '../common/hooks/useUserName';
import usePcode from '../common/hooks/usePcode';

import LogIn from '../pages/logIn';
import MainMenu from '../pages/mainMenu';

import { identityAPI } from '../api/identityAPI';

import Spinner from '../components/spinner';
import { useState } from 'react';

export default function App() {
    const { token, setToken } = useToken();
    const { setLogedUserName } = useUserName();
    const { setLoggedPCode } = usePcode();

    const [isProcessing, setIsProcessing] = useState(false);

    const logOut = () => {
        setIsProcessing(true);
        identityAPI.logOut().then(() => { }).finally(() => {
            setToken('');
            setLogedUserName('');
            setLoggedPCode('');

            setIsProcessing(false);
        });
    }

    if (!token)
        return <LogIn setToken={setToken} setLogedUserName={setLogedUserName} setLoggedPcode={setLoggedPCode} />;

    return (
        <>
            {isProcessing && <div className="text-center"><Spinner /></div>}
            {isProcessing === false &&
                <Routes>
                    <Route path={'*'} element={<Navigate to={'/mainmenu'} />}></Route>
                    <Route path={mainMenuParentRoutePath} element={<MainMenu logOut={logOut} />}></Route>
                </Routes>
            }
        </>
    )
}