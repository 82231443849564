import { useEffect, useState, useRef } from "react";
import { sectionAPI } from '../../api/sectionAPI';

export default function Sections({ sectionCode, setSectionCode }) {
    const didMount = useRef(false);

    const [sections, setSections] = useState([]);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            sectionAPI.getSections().then((data) => setSections(data));
        }
    }, [])

    return (
        <select className="form-select" onChange={(e) => setSectionCode(e.target.value)} value={sectionCode } disabled>
            <option value="">Select a section</option>
            {
                sections.map((data) => (
                    <option key={data.sectionCode} value={data.sectionCode}>{data.sectionName}</option>
                ))
            }
        </select>
    );
}