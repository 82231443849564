import { QrReader } from 'react-qr-reader';

import './qrCodeScanner.css';

export default function QRCodeScanner({ setQrCode, setIsShowQrReader, triggerAfterScan }) {
    return (
        <>
            <div className="row qrCodeScanner">
                <div className="col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                    <h4 className="text-center text-dark float-left">QR Scan</h4>
                    <button className="float-end btn btn-sm btn-secondary" type="button" onClick={() => setIsShowQrReader(false)}>X</button>
                    <QrReader
                        constraints={{
                            aspectRatio: "1",
                            facingMode: "environment"
                        }}
                        scanDelay={250}
                        onResult={(result, error) => {
                            if (!!result) {
                                let code = result?.getText();
                                setQrCode(code);
                                setIsShowQrReader(false);
                                triggerAfterScan(code);
                            }
                        }}
                    />
                </div>
                <div className="col-0 col-sm-0 col-md-2 col-lg-2 col-xl-2 col-xxl-2"></div>
            </div>
        </>
    );
}