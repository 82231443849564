import { api } from './configs/axiosConfig';

const endPoint = "/jobs";

export const jobAPI = {
    saveJobInOutReturn: async function (type, jobNo, sectionCode, reason) {
        const reponse = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { type, jobNo, sectionCode, reason }
        });

        return reponse.data;
    },
    getJobDetails: async function (jobNo) {
        const reponse = await api.request({
            url: `${endPoint}`,
            method: 'GET',
            params: { jobNo }
        });

        return reponse.data;
    },
    getTargets: async function () {
        const reponse = await api.request({
            url: `${endPoint}/targets`,
            method: 'GET'
        });

        return reponse.data;
    }
}